import * as moment from "moment";

import { ADULT_AGE, DATE_FORMAT } from "../../utils/constants";
import { BaseOptions, LocalizedBaseOptions } from "./form.types";
import { CMSOptions, getCMSOptions } from "../settings";
import { CustomLanguageCalendarWidgetOptions, getCustomLanguageCalendar } from "../../components/utils";
import { FormSpec, SomeInputSpec, getHelpId } from "../../form-specs";
import { FormWidgetSpec, Widget } from "../";
import { getFormFieldVariable, getFormFieldsDescription, isFormFieldRequired } from "./form.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { WidgetGroup } from "../widget.enum";
import { getBaseOptionsSpecs } from "./";
import { getLocalizedContent } from "../../utils/localizedContent.util";
import namespaceList from "../../i18n/namespaceList";

interface LocalizedWidgetOptions extends LocalizedBaseOptions {}

interface WidgetOptions extends BaseOptions<LocalizedWidgetOptions>, CustomLanguageCalendarWidgetOptions {
    default: string;
    useAsDateSelectInput: boolean;
    useForBirthDate?: boolean;
    minimumAge?: number;
    useForFutureDates?: boolean;
    persistDateFormat?: boolean;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-date-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "dateWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "dateWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    help: getHelpId("0mbcCB-general"),
                    properties: [
                        [
                            {
                                variable: "useAsDateSelectInput",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDateSelectInput"),
                                type: "checkbox",
                            },
                            {
                                variable: "persistDateFormat",
                                label: getI18nLocaleObject(namespaceList.admin, "persistDateFormat"),
                                type: "checkbox",
                            },
                            ...getBaseOptionsSpecs(),
                            ...getCustomLanguageCalendar(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "options"),
                    properties: [
                        [
                            {
                                variable: "useForBirthDate",
                                label: getI18nLocaleObject(namespaceList.admin, "useForBirthDate"),
                                type: "checkbox",
                            },
                            {
                                variable: "minimumAge",
                                label: getI18nLocaleObject(namespaceList.admin, "minimumAge"),
                                type: "number",
                                visible: (item: WidgetOptions) => !!item.useForBirthDate,
                            },
                            {
                                variable: "useForFutureDates",
                                label: getI18nLocaleObject(namespaceList.admin, "useForFutureDates"),
                                type: "checkbox",
                            },
                        ],
                    ],
                    visible: (item: WidgetOptions) => !item.useforBookingsModule,
                },
            ],
        },
    ],
};

export const dateWidget: FormWidgetSpec<WidgetOptions> = {
    id: "form-date",
    type: "form",
    widgetGroup: WidgetGroup ? WidgetGroup.INPUT : 6,
    name: getI18nLocaleObject(namespaceList.pluginForm, "dateWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "dateWidgetDesc"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        fieldId: "",
        required: false,
        default: "",
        localized: [],
        dynamicFieldId: "",
        useAsDateSelectInput: false,
    }),
    async toInputSpec(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<SomeInputSpec<any, any>> {
        const { currentLocale, site } = context;
        const { useforBookingsModule, useAsDateSelectInput, useForBirthDate, minimumAge } = widget.options;
        const localizedLabel: string = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized || [], keys: ["label"] })?.label || "";
        const localizedMessage: string = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized || [], keys: ["message"] })?.message || "";

        const cmsOptions: CMSOptions = await getCMSOptions(context.cmsApi);
        const minBookAge: number = useForBirthDate && minimumAge ? minimumAge : cmsOptions.minBookAge || ADULT_AGE;
        const maxBookBirthDate: string | undefined =
            ((useforBookingsModule || (useForBirthDate && useAsDateSelectInput)) && moment().subtract(minBookAge, "years").format(DATE_FORMAT.MXTS)) || undefined;

        return {
            type: useAsDateSelectInput ? "dateSelect" : "date",
            variable: getFormFieldVariable(widget.options),
            label: localizedLabel,
            default: widget.options.default,
            required: await isFormFieldRequired(widget.options, context),
            message: localizedMessage,
            maxDate: maxBookBirthDate,
            options: widget.options,
        };
    },
    instanceDescription({ widget }): string {
        const formFrieldDescriptionParams = {
            /* jscpd:ignore-start */
            fieldId: widget.options.fieldId,
            dynamicFieldId: widget.options.dynamicFieldId,
            useforBookingsModule: widget.options.useforBookingsModule,
            defaultDescription: getI18nLocaleString(namespaceList.pluginForm, "dateWidgetDesc"),
            /* jscpd:ignore-end */
        };
        return getFormFieldsDescription(formFrieldDescriptionParams);
    },
};
